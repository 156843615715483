<template>
    <div class="container d-flex justify-content-center" style="margin-top: 20%">
        <div class="card shadow-sm" style="width:31%">
            <div class="card-body">
                <div
                class="px-3"
                style="border-left: 3px solid #ffc107 !important;"
                >
                    <span class="">TOTAL NO. STUDENTS : <span class="font-weight-bold">{{addComma(totalNumberStudents)}}</span></span>
                </div>
                <div
                class="px-3 mt-2"
                style="border-left: 3px solid #321fdb !important;"
                >
                    <span class="">TOTAL APPROXIMATE TREES PER STUDENT : <span class="font-weight-bold">{{addComma(totalNumberAppTreesPerStudent)}}</span></span>
                </div>
                <div
                class="px-3 mt-2"
                style="border-left: 3px solid #2eb85c !important;"
                >
                    <span class="">TOTAL TREES PLANTED : <span class="font-weight-bold">{{addComma(totalTreesPlanted)}}</span></span>
                </div>
                <div
                class="px-3 mt-2"
                style="border-left: 3px solid #FF0000 !important;"
                >
                    <span class="">REMAINING TREES TO BE PLANTED : <span class="font-weight-bold">{{ addComma(oneM - totalTreesPlanted)}}</span></span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>


export default {
  data() {
    return {
      loading: false,
      list_heis: [],
      totalNumberStudents: '',
      totalNumberAppTreesPerStudent: '',
      totalTreesPlanted: '',
      oneM: 1000000
    }
  },
  mounted() {
   this.getTotalTreesPublic();
   this.getListHEIsPublic();
  },
  methods: {

    addComma(amount) {
        let num_parts = amount.toString().split(".");
        num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        return num_parts.join(".");
    },
    async getTotalTreesPublic() {
        try {
            const response = await axios.get('api/get-total-trees-planted-public')
            .then((response) => {

                let total_trees = response.data.total_trees; 
                let len = total_trees.length;
                let totalTreesPlantd = 0;
                for(let i=0; i<len; i++) {
                    totalTreesPlantd = totalTreesPlantd + total_trees[i].no_trees;
                } 
                this.totalTreesPlanted = totalTreesPlantd;        
            })
        } catch(error) {
            console.log("err", error);
            alert('Something went wrong!');
        }
    },
    async getListHEIsPublic() {
        try {
            const response = await axios.get('api/get-list-heis-public')
            .then((response) => {
            this.list_heis = response.data.list_heis;  
            console.log("list heis", this.list_heis);
            
            let len = this.list_heis.length;
            let totalNumberStdents = 0;
            let totalNumberAppTreesPerStdent = 0;
            for(let i=0; i<len; i++) {
                totalNumberStdents = totalNumberStdents + this.list_heis[i].no_students;
                totalNumberAppTreesPerStdent = totalNumberAppTreesPerStdent + this.list_heis[i].app_trees_per_student;
            }
            this.totalNumberStudents = totalNumberStdents;
            this.totalNumberAppTreesPerStudent = totalNumberAppTreesPerStdent;
            })
        } catch(error) {
            console.log("err", error);
            alert('Something went wrong!');
        }
    },

  }
}
</script>